$primary: #532565;
$secondary: #982568;
$success: #532565;
$info: #bf362e;
$warning: #e07c3e;
$danger: #981f32;
$light: #dddddd;
$dark: #373a3c;

$font-family-sans-serif: 'Montserrat', sans-serif;
$link-color: $secondary;

@import '~bootstrap/scss/bootstrap.scss';

body {
  color: $dark;
  line-height: 1.8rem;
}

.app-container {
  padding-top: 72px;
}

.text-muted {
  color: #616A70 !important;
}

.header-cap {
  background-color: $white;
  .graphicElementGradient {
    background-image: linear-gradient(
      to right,
      #532565 10%,
      #982568 30%,
      #981f32 50%,
      #bf362e 76%,
      #e07c3e 100%
    );
  }
}

.header {
  svg {
    font-size: 1.75rem;
  }
  .navbar-nav a.nav-link {
    color: $dark;
    &.active {
      font-weight: $font-weight-bold;
      color: $secondary;
    }
  }
}

.resizing-container {
  padding: 0;
}

.login-panel.login-panel {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  margin-top: 0.75rem;
  margin-bottom: 2.5rem;
  padding: 0;
  .btn {
    background-color: #7a4e99;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    &:hover {
      background-color: $primary;
    }
  }
  .login-col-img {
    img {
      border-radius: 1.6rem;
      min-height: 100%;
      max-height: 350px;
      min-width: 100%;
      max-width: 100%;
    }
    @media (min-width: 768px) {
      order: 0;
    }
  }
  .login-logo-img {
    max-height: 200px;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.title-toolbar.title-toolbar {
  margin-bottom: 2.5rem;
  .col-navbar {
    .navbar {
      background-color: $secondary;
      .btn:not(.dropdown-item) {
        color: $white;
        padding: 0 8px;
      }
      .navbar-brand {
        font-size: 32px;
        padding-left: 0.5rem;
      }
      .navbar-text {
        padding: 0;
      }
    }
  }
  .logo {
    width: 100%;
    background-color: $white;
    max-width: 260px;
    min-width: 112px;
  }
}

.document-panel.document-panel {
  .action-bar {
    background-color: white;
    position: sticky;
    top: 73px;
  }
  .file {
    a:before {
      content: 'View File: ' attr(data-name);
      background: $dark;
      color: $white;
      font-size: 80%;
      padding: 0 0.5rem;
      border-radius: 5px;
      position:absolute;
      transform:translate(15px, 25px);
      display:none;
    }
  }
  a:hover:before {
    display:block;
  }
}

.story-grid.story-grid {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  .rdw-editor-main {
    height: 350px;
    overflow: hidden;
    mask-image: linear-gradient(black 90%, transparent 100%);
  }
  .btn.btn-outline-secondary {
    border-color: $light;
  }
}

.story-panel {
  position: relative;
  .btn {
    color: $secondary;
    position: absolute;
    top: 0;
    right: -5px;
  }
}

@media (min-width: 768px) {
  .slider-content {
    .files {
      max-height: calc(100vh - 160px);
      overflow-y: auto;
    }
  }
}

.rdw-link-decorator-wrapper {
  a,
  span {
    color: $secondary;
  }
  .rdw-link-decorator-icon {
    display: none;
  }
}

.rendered-file-browser.rendered-file-browser {
  .files table {
    margin-bottom: 0;
  }
  a,
  .item-actions label {
    cursor: pointer;
    color: $dark;
  }
}

.footer {
  color: $gray-600;
  padding: 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2em;
  p {
    margin-bottom: 0.25rem;
  }
  .btn {
    margin: 0;
    padding: 0;
  }
}

.maintenance-container {
  background-color: #ddd3e0;
  border-color: #cfc2d4;
  color: #2b1335;
}
